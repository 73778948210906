import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { AppMainComponent } from '@core/components';

import { DashboardDemoComponent } from './demo/view/dashboarddemo.component';
import { FormLayoutDemoComponent } from './demo/view/formlayoutdemo.component';
import { FloatLabelDemoComponent } from './demo/view/floatlabeldemo.component';
import { InvalidStateDemoComponent } from './demo/view/invalidstatedemo.component';
import { PanelsDemoComponent } from './demo/view/panelsdemo.component';
import { OverlaysDemoComponent } from './demo/view/overlaysdemo.component';
import { MediaDemoComponent } from './demo/view/mediademo.component';
import { MessagesDemoComponent } from './demo/view/messagesdemo.component';
import { MiscDemoComponent } from './demo/view/miscdemo.component';
import { EmptyDemoComponent } from './demo/view/emptydemo.component';
import { ChartsDemoComponent } from './demo/view/chartsdemo.component';
import { FileDemoComponent } from './demo/view/filedemo.component';
import { DocumentationComponent } from './demo/view/documentation.component';
import { AppNotfoundComponent } from './pagesTemp/app.notfound.component';
import { AppErrorComponent } from './pagesTemp/app.error.component';
import { AppAccessdeniedComponent } from './pagesTemp/app.accessdenied.component';
import { InputDemoComponent } from './demo/view/inputdemo.component';
import { ButtonDemoComponent } from './demo/view/buttondemo.component';
import { TableDemoComponent } from './demo/view/tabledemo.component';
import { ListDemoComponent } from './demo/view/listdemo.component';
import { AppTimelineDemoComponent } from './pagesTemp/app.timelinedemo.component';
import { TreeDemoComponent } from './demo/view/treedemo.component';
import { IconsComponent } from './utilities/icons.component';
import { AppCrudComponent } from './pagesTemp/app.crud.component';
import { AppCalendarComponent } from './pagesTemp/app.calendar.component';
import { AppInvoiceComponent } from './pagesTemp/app.invoice.component';
import { AppHelpComponent } from './pagesTemp/app.help.component';
import { BlocksComponent } from './blocks/blocks/blocks.component';
import { UnAuthGuard } from '@core/guards/un-auth.guard';

@NgModule({
    imports: [
        RouterModule.forRoot([
            {
                path: '', component: AppMainComponent,
                children: [
                    {
                        path: '',
                        data: {
                            breadcrumb: {
                                title: 'Dashboard',
                                navigate: true,
                            }

                        },
                        component: DashboardDemoComponent
                    },
                    {
                        path: 'players',
                        loadChildren: () => import('@pages/players/players.module').then(m => m.PlayersModule)
                    },
                    {
                        path: 'payments',
                        loadChildren: () => import('./pages/payments/payments.module').then(m => m.PaymentsModule)
                    },
                    {
                        path: 'bets',
                        loadChildren: () => import('./pages/bets/bets.module').then(m => m.BetsModule)
                    },
                    {
                        path: 'partners',
                        loadChildren: () => import('./pages/partners/partners.module').then(m => m.PartnersModule)
                    },
                    {
                        path: 'users',
                        loadChildren: () => import('./pages/users/users.module').then(m => m.UsersModule)
                    },
                    {
                        path: 'reports',
                        loadChildren: () => import('./pages/reports/reports.module').then(m => m.ReportsModule)
                    },
                    {
                        path: 'notifications',
                        loadChildren: () => import('./pages/notifications/notifications.module').then(m => m.NotificationsModule)
                    },
                    {
                        path: 'providers',
                        loadChildren: () => import('./pages/providers/providers.module').then(m => m.ProvidersModule)
                    },
                    {
                        path: 'ag-table',
                        loadChildren: () => import('./pagesTemp/ag-table/ag-table.module').then(m => m.AGTableModule)
                    },
                    { path: 'uikit/formlayout', component: FormLayoutDemoComponent },
                    { path: 'uikit/floatlabel', component: FloatLabelDemoComponent },
                    { path: 'uikit/invalidstate', component: InvalidStateDemoComponent },
                    { path: 'uikit/input', component: InputDemoComponent },
                    { path: 'uikit/button', component: ButtonDemoComponent },
                    { path: 'uikit/table', component: TableDemoComponent },
                    { path: 'uikit/list', component: ListDemoComponent },
                    { path: 'uikit/tree', component: TreeDemoComponent },
                    { path: 'uikit/panel', component: PanelsDemoComponent },
                    { path: 'uikit/overlay', component: OverlaysDemoComponent },
                    {
                        path: 'uikit/menu',
                        loadChildren: () => import('./demo/view/menus/menus.module').then(m => m.MenusModule)
                    },
                    { path: 'uikit/media', component: MediaDemoComponent },
                    { path: 'uikit/message', component: MessagesDemoComponent },
                    { path: 'uikit/misc', component: MiscDemoComponent },
                    { path: 'uikit/charts', component: ChartsDemoComponent },
                    { path: 'uikit/file', component: FileDemoComponent },
                    { path: 'utilities/icons', component: IconsComponent },
                    { path: 'pages/crud', component: AppCrudComponent },
                    { path: 'pages/calendar', component: AppCalendarComponent },
                    { path: 'pages/timeline', component: AppTimelineDemoComponent },
                    { path: 'pages/invoice', component: AppInvoiceComponent },
                    { path: 'pages/help', component: AppHelpComponent },
                    { path: 'pages/empty', component: EmptyDemoComponent },
                    { path: 'documentation', component: DocumentationComponent },
                    { path: 'blocks', component: BlocksComponent }
                ]
            },
            { path: 'error', component: AppErrorComponent },
            { path: 'access', component: AppAccessdeniedComponent },
            { path: 'notfound', component: AppNotfoundComponent },


            {
                path: 'login',
                canActivate: [UnAuthGuard],
                loadChildren: () => import('@pages/auth/auth.module').then(m => m.AuthModule)
            },


            { path: '**', redirectTo: '/notfound' },
        ], { scrollPositionRestoration: 'enabled' })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
