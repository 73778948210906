import { Injectable } from '@angular/core';
import { catchError, tap } from 'rxjs/operators';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpEventType,
    HttpHandler,
    HttpRequest
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { AuthService } from '@core/services';
import { Router } from '@angular/router';

@Injectable()
export class HttpInterceptor implements HttpInterceptor {
    constructor(
        private authService: AuthService,
        private router: Router,
    ) {

    }
    private static modifyRequest(
        req: HttpRequest<any>,
        Token?: string | null,
        TimeZone?: number,
        LanguageId?: string,
        clientId?: string | null | number,
    ): HttpRequest<any> {
        if (!req.url.includes('.json') && !(req.body instanceof FormData)) {
            return req.clone({
                body: {
                    ...req.body,
                    Token,
                    TimeZone,
                    LanguageId,
                    // ClientId: clientId ? clientId : '0'
                }
            });
        }
        return req;
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        req = HttpInterceptor.modifyRequest(
            req,
            req.body?.Token ? req.body.Token : this.authService.tokenGetter() ? this.authService.tokenGetter() : '',
            // this.configService.timeZone,
            // this.languageService.currentLanguage$.getValue(),
            // +this.cookieService.get('ClientId'))
        );
        return next.handle(req)
            .pipe(
                tap(event => {
                    if (event.type === HttpEventType.Response) {
                        // need to be implemented
                        if (event.body.ResponseCode === 28 || event.body.ResponseCode === 29) {
                            if (!!this.authService.tokenGetter()) {
                                this.authService.logout();
                            } else {
                                this.router.navigate(['/login']);
                            }
                        }
                    }
                }),
                catchError(err => {
                    if (err instanceof HttpErrorResponse && err.status === 0) {
                        console.log('Check Your Internet Connection And Try again Later');
                    }
                    return throwError(err);
                })
            );

    }
}
