import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from './http.service';
import { environment } from '@env/environment';

import { Methods } from '@core/enums';
import { ICreateComment, INote, INoteHistory, IResponseData } from '@core/interfaces';

@Injectable({
    providedIn: 'root'
})
export class NotesService {

    constructor(
        private http: HttpService
    ) {
    }

    public saveNote(note: INote): Observable<IResponseData<INoteHistory[]>> {
        const payload = {
            Controller: 'Util',
            Method: 'SaveNote',
            RequestObject: note
        };

        return this.http.request(
            'post',
            environment.ApiUrl + Methods.MAIN_REQUEST,
            payload,
            false,
            null,
            true
        );
    }

    public getNotes(data): Observable<IResponseData<INoteHistory[]>> {
        const payload = {
            Controller: 'Util',
            Method: 'GetNotes',
            RequestObject: {
                ...data
            }
        };
        return this.http.request(
            'post',
            environment.ApiUrl + Methods.MAIN_REQUEST, payload,
            false,
            null,
            true);
    }

    public getNoteHistory(noteId: number): Observable<IResponseData<INoteHistory[]>> {
        const payload = {
            Controller: 'Util',
            Method: 'GetNoteHistory',
            RequestObject: noteId
        };
        return this.http.request(
            'post',
            environment.ApiUrl + Methods.MAIN_REQUEST, payload,
            false,
            null,
            true);
    }

    public saveComment(comment: ICreateComment): Observable<IResponseData<INoteHistory[]>> {
        const payload = {
            Controller: 'DeviceDetectionReports',
            Method: 'UpdateDeviceComment',
            RequestObject: comment
        };

        return this.http.request(
            'post',
            environment.ApiUrl + Methods.MAIN_REQUEST,
            payload,
            false,
            null,
            true
        );
    }

}
