import { Injectable } from '@angular/core';
import { AppConfig } from '@app/demo/domain/appconfig';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@env/environment';

import { AuthService, HttpService } from '@core/services';
import { IPartnerConfig, IPartnerConfigs, ISelectedTheme } from '@core/interfaces';
import { Methods } from '@core/enums';

@Injectable({
    providedIn: 'root'
})
export class ConfigService {

    public config: AppConfig = {
        theme: 'lara-light-indigo',
        dark: false,
        inputStyle: 'outlined',
        ripple: true
    };

    public menuMode = 'static';

    public colorScheme = localStorage.getItem('selectedTheme') || 'light';

    public menuTheme = 'layout-sidebar-darkgray';

    public inputStyle = 'outlined';

    public ripple: boolean = true;

    private configUpdate = new Subject<AppConfig>();

    public configUpdate$ = this.configUpdate.asObservable();

    public configClick: boolean;

    public configActive: boolean;

    public selectedTheme: ISelectedTheme = {};

    public partnerConfigs: IPartnerConfigs[];

    public reCaptchaKey: string;

    constructor(
        private http: HttpService,
        private authService: AuthService,
    ) {
    }

    onConfigClick(event): void {
        this.configClick = true;
    }


    updateConfig(config: AppConfig): void {
        this.config = config;
        this.configUpdate.next(config);
    }

    getConfig(): AppConfig {
        return this.config;
    }

    get getPartnerConfigsData(): IPartnerConfigs[] | null {
        return JSON.parse(localStorage.getItem('generalConfigs'));
    }

    setRootColorVariables(): void {
        // const colors = [
        //     {
        //         colors: {
        //             'primary-color': '#2196F3',
        //             'primary-dark-color': '#0d89ec',
        //             'primary-darker-color': '#0b7ad1',
        //             'primary-light-color': '#a6d5fa',
        //             'primary-color-text': '#fff',
        //             'sidebar-color': '#4a5d62',
        //             'sidebar-color-alt': '#2e3439',
        //             'sidebar-active-menuitem-color': '#3f474e',
        //             'sidebar-text-color': '#fff',
        //             'sidebar-text-active-color': '#fff',
        //             'sidebar-hover-color': '#4b545c',
        //         },
        //         logoUrl: 'assets/layout/images/logo-white.svg'
        //     },
        //
        //     {
        //         colors: {
        //             'primary-color': '#90CAF9',
        //             'primary-dark-color': '#6bb8f7',
        //             'primary-darker-color': '#3ba1f4',
        //             'primary-light-color': '#fcfeff',
        //             'primary-color-text': '#000',
        //             'sidebar-color': '#4a5d62',
        //             'sidebar-color-alt': '#2e3439',
        //             'sidebar-active-menuitem-color': '#3f474e',
        //             'sidebar-text-color': '#fff',
        //             'sidebar-text-active-color': '#fff',
        //             'sidebar-hover-color': '#4b545c',
        //         },
        //         logoUrl: 'assets/layout/images/logo-dark.svg'
        //     }
        // ];
        //
        // localStorage.setItem('colorScheme', JSON.stringify(colors));

        const root = document.documentElement;
        const configs: IPartnerConfigs[] = JSON.parse(localStorage.getItem('generalConfigs'));
        const selectedScheme: IPartnerConfig[] = this.colorScheme === 'light' ?
            configs?.find(config => config.Key === 'LightColors').Value :
            configs?.find(config => config.Key === 'DarkColors').Value;
        selectedScheme?.forEach(color => {
            root.style.setProperty(`--${color.Key}`, color.Value);
        });
    }

    setSelectedTheme(): void {
        const configs: IPartnerConfigs[] = JSON.parse(localStorage.getItem('generalConfigs'));
        const selectedTheme: IPartnerConfig[] = this.colorScheme === 'light' ?
            configs?.find(config => config.Key === 'Light').Value :
            configs?.find(config => config.Key === 'Dark').Value;

        selectedTheme?.forEach(scheme => {
            this.selectedTheme[scheme.Key] = scheme.Value;
        });
    }

   async getReCaptchaKey(): Promise<string> {
        if (!localStorage.getItem('generalConfigs')) {
            await this.getPartnerConfigs();
        }
        return JSON.parse(localStorage.getItem('generalConfigs'))?.find(config => config.Key === 'General')
            .Value?.find(config => config.Key === 'ReCaptchaKey')?.Value;
    }

    async load(): Promise<void> {
        if (!this.authService.storageRequiredFieldsExist()) {
            return this.authService.logout();
        }
        if (!localStorage.getItem('generalConfigs')) {
            await this.getPartnerConfigs();
        }
    }

    async getPartnerConfigs(): Promise<void> {
        return this.http.request('get', environment.ApiUrl + Methods.PARTNER_ADMIN_CONFIG)
            .pipe(map(response => response.ResponseObject))
            .toPromise()
            .then((data: IPartnerConfigs[]) => {
                localStorage.setItem('generalConfigs', JSON.stringify(data));
                this.partnerConfigs = data;
                this.setSelectedTheme();
                this.setRootColorVariables();
            });
    }

}
