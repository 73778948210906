import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpService } from '@core/services/http.service';
import { environment } from '@env/environment';
import { Methods } from '@core/enums';
import { IGameProvider, IResponseData } from '@core/interfaces';

@Injectable({
    providedIn: 'root'
})
export class CommonService {

    public providerStates = [
        {
            Background: '#79F2B8',
            Color: '#3F3F3F',
            Id: 1,
            Info: null,
            Name: 'Active',
            NickName: null
        },
        {
            Background: '#ffd6da',
            Color: '#3F3F3F',
            Id: 2,
            Info: null,
            Name: 'Inactive',
            NickName: null
        }
    ];
    private noteStates = [
        {
            Background: '#79F2B8',
            Color: '#3F3F3F',
            Id: 1,
            Info: null,
            Name: 'Active',
            NickName: null
        },
        {
            Background: '#EE6464',
            Color: '#3F3F3F',
            Id: 2,
            Info: null,
            Name: 'Deleted',
            NickName: null
        }
    ];

    private notificationStates = [
        {
            Background: '#e3e3e8',
            Color: '#b3b3bc',
            Id: 1,
            Info: null,
            Name: 'Active',
            NickName: null
        },
        {
            Background: '#79F2B8',
            Color: '#3F3F3F',
            Id: 2,
            Info: null,
            Name: 'Sent',
            NickName: null
        },
        {
            Background: '#EE6464',
            Color: '#3F3F3F',
            Id: 3,
            Info: null,
            Name: 'Failed',
            NickName: null
        }
    ];

    public playerStates$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
    public noteStates$: BehaviorSubject<any> = new BehaviorSubject<any>(this.noteStates);
    public playerCategories$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
    public partners$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
    public partnerStates$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
    public documentStates$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
    public documentTypes$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
    public deviceTypes$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
    public betTypes$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
    public commonEnumModels$: BehaviorSubject<any> = new BehaviorSubject<any>({});
    public countries$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
    public cities$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
    public userStates$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
    public userTypes$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
    public gameProviders$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
    public notificationStates$: BehaviorSubject<any> = new BehaviorSubject<any>(this.notificationStates);
    public providerStates$: BehaviorSubject<any> = new BehaviorSubject<any>(this.providerStates);

    constructor(
        private http: HttpService,
    ) {
        this.loadPlayerStates();
        this.loadPlayerCategories();
        this.loadPartnerStates();
        this.loadPartners();
        this.loadCommonEnumModels();
        this.loadDocumentStatesEnumModels();
        this.loadDeviceTypes();
        this.loadBetTypes();
        this.loadRegions();
        this.loadDocumentTypesEnumModels();
        this.loadUserStates();
        this.loadUserTypes();
        this.loadGameProviders();
        this.loadNotificationStates();
    }

    private loadPlayerStates(): void {
        if (localStorage.getItem('playerStates')) {
            this.playerStates$.next(JSON.parse(localStorage.getItem('playerStates')));
        } else {
            const payload = {
                Controller: 'EnumerationModel',
                Method: 'GetClientStatesEnum',
                RequestObject: {}
            };

            this.http.request(
                'post',
                environment.ApiUrl + Methods.MAIN_REQUEST, payload,
                false,
                null,
                true)
                .subscribe(data => {
                    if (data.ResponseCode === 0 && data.ResponseObject) {
                        data.ResponseObject.map(status => {
                            status.Background = status.Name === 'Active' ? '#79F2B8' :
                                (status.Name === 'Blocked For Bet' || status.Name === 'Blocked For Deposit' || status.Name === 'Blocked For Withdraw') ? '#FFD6DA' :
                                    (status.Name === 'Force Block' || status.Name === 'Full Blocked') ? '#EE6464' : '#E3E3E8';

                            status.Color = status.Name === 'Active' ? '#3F3F3F' :
                                (status.Name === 'Blocked For Bet' || status.Name === 'Blocked For Deposit' || status.Name === 'Blocked For Withdraw') ? '#3F3F3F' :
                                    (status.Name === 'Force Block' || status.Name === 'Full Blocked') ? '#3F3F3F' : '#B3B3BC';
                        });
                        localStorage.setItem('playerStates', JSON.stringify(data.ResponseObject));
                        this.playerStates$.next(data.ResponseObject);
                    }
                });
        }
    }

    public loadUserTypes(): void {
        if (localStorage.getItem('userTypes')) {
            this.userTypes$.next(JSON.parse(localStorage.getItem('userTypes')));
        } else {
            const payload = {
                Controller: 'EnumerationModel',
                Method: 'GetUserTypesEnum',
                RequestObject: {}
            };

            this.http.request('post',
                environment.ApiUrl + Methods.MAIN_REQUEST,
                payload,
                false,
                null,
                true
            )
                .subscribe(data => {
                    if (data.ResponseCode === 0 && data.ResponseObject) {
                        localStorage.setItem('userTypes', JSON.stringify(data.ResponseObject));
                        this.userTypes$.next(JSON.parse(localStorage.getItem('userTypes')));
                    }
                });
        }
    }

    public loadPartners(): void {
        if (localStorage.getItem('partners')) {
            this.partners$.next(JSON.parse(localStorage.getItem('partners')));
        } else {
            this.getPartners()
                .subscribe(data => {
                    if (data.ResponseCode === 0 && data.ResponseObject) {
                        localStorage.setItem('partners', JSON.stringify(data.ResponseObject.Entities));
                        this.partners$.next(data.ResponseObject.Entities);
                    }
                });
        }
    }

    public loadUserStates(): void {
        if (localStorage.getItem('userStates')) {
            this.userStates$.next(JSON.parse(localStorage.getItem('userStates')));
        } else {
            const payload = {
                Controller: 'EnumerationModel',
                Method: 'GetUserStatesEnum',
                RequestObject: {}
            };

            this.http.request('post',
                environment.ApiUrl + Methods.MAIN_REQUEST,
                payload,
                false,
                null,
                true)
                .subscribe(data => {
                    if (data.ResponseCode === 0 && data.ResponseObject) {
                        data.ResponseObject.map(status => {
                            status.Background = status.Name === 'Open' ? '#79F2B8' :
                                (status.Name === 'Closed' || status.Name === 'Locked' || status.Name === 'Blocked') ? '#FFD6DA' :
                                    (status.Name === 'Products' || status.Name === 'Force Block') ? '#EE6464' : '#E3E3E8';

                            status.Color = status.Name === 'Open' ? '#3F3F3F' :
                                (status.Name === 'Closed' || status.Name === 'Locked' || status.Name === 'Blocked') ? '#3F3F3F' :
                                    (status.Name === 'Products' || status.Name === 'Force Block') ? '#3F3F3F' : '#B3B3BC';
                        });
                        localStorage.setItem('userStates', JSON.stringify(data.ResponseObject));
                        this.userStates$.next(data.ResponseObject);
                    }
                });
        }
    }

    public getPartners(): Observable<any> {
        const payload = {
            Controller: 'Partner',
            Method: 'GetPartners',
            RequestObject: {}
        };
        return this.http.request('post', environment.ApiUrl + Methods.MAIN_REQUEST, payload);
    }

    private loadPartnerStates(): void {
        if (localStorage.getItem('partnerStates')) {
            this.partnerStates$.next(JSON.parse(localStorage.getItem('partnerStates')));
        } else {
            const payload = {
                Controller: 'EnumerationModel',
                Method: 'GetPartnersStateEnum',
                RequestObject: {}
            };

            this.http.request('post',
                environment.ApiUrl + Methods.MAIN_REQUEST, payload,
                false,
                null,
                true)
                .subscribe(data => {
                    if (data.ResponseCode === 0 && data.ResponseObject) {
                        data.ResponseObject.map(status => {
                            status.Background = status.Name === 'Active' ? '#79F2B8' : '#EE6464';

                            status.Color = status.Name === 'Active' ? '#3F3F3F' : '#3F3F3F';
                        });
                        this.partnerStates$.next(data.ResponseObject);
                        localStorage.setItem('partnerStates', JSON.stringify(data.ResponseObject));
                    }
                });
        }
    }

    public loadDeviceTypes(): void {
        if (localStorage.getItem('deviceTypes')) {
            this.deviceTypes$.next(JSON.parse(localStorage.getItem('deviceTypes')));
        } else {
            const payload = {
                Controller: 'EnumerationModel',
                Method: 'GetDeviceTypesEnum',
                RequestObject: {}
            };

            this.http.request(
                'post',
                environment.ApiUrl + Methods.MAIN_REQUEST, payload,
                false,
                null,
                true)
                .subscribe(data => {
                    if (data.ResponseCode === 0 && data.ResponseObject) {
                        localStorage.setItem('deviceTypes', JSON.stringify(data.ResponseObject));
                        this.deviceTypes$.next(data.ResponseObject);
                    }
                });
        }
    }

    public loadRegions(): void {
        if (localStorage.getItem('countries')) {
            this.countries$.next(JSON.parse(localStorage.getItem('countries')));
        } else {
            const payload = {
                Controller: 'Region',
                Method: 'GetRegions',
                RequestObject: {}
            };

            this.http.request(
                'post',
                environment.ApiUrl + Methods.MAIN_REQUEST, payload,
                false,
                null,
                true)
                .subscribe(data => {
                    localStorage.setItem('countries', JSON.stringify(data.ResponseObject));
                    this.countries$.next(data.ResponseObject.filter(country => country.TypeId === 5));
                    this.cities$.next(data.ResponseObject.filter(country => country.TypeId === 5));
                });
        }
    }

    public loadPlayerCategories(): void {
        if (localStorage.getItem('playerCategories')) {
            this.playerCategories$.next(JSON.parse(localStorage.getItem('playerCategories')));
        } else {
            const payload = {
                Controller: 'EnumerationModel',
                Method: 'GetClientCategoriesEnum',
                RequestObject: {}
            };

            this.http.request(
                'post',
                environment.ApiUrl + Methods.MAIN_REQUEST, payload,
                false,
                null,
                true)
                .subscribe(data => {
                    if (data.ResponseCode === 0 && data.ResponseObject) {
                        localStorage.setItem('playerCategories', JSON.stringify(data.ResponseObject));
                        this.playerCategories$.next(data.ResponseObject);
                    }
                });
        }
    }

    public loadBetTypes(): void {
        if (localStorage.getItem('betTypes')) {
            this.betTypes$.next(JSON.parse(localStorage.getItem('betTypes')));
        } else {
            const payload = {
                Controller: 'EnumerationModel',
                Method: 'GetCreditDocumentTypesEnum',
                RequestObject: {}
            };

            this.http.request(
                'post',
                environment.ApiUrl + Methods.MAIN_REQUEST, payload,
                false,
                null,
                true)
                .subscribe(data => {
                    if (data.ResponseCode === 0 && data.ResponseObject) {
                        localStorage.setItem('betTypes', JSON.stringify(data.ResponseObject));
                        this.betTypes$.next(data.ResponseObject);
                    }
                });
        }
    }

    private loadCommonEnumModels(): void {
        if (localStorage.getItem('commonEnumModels')) {
            this.commonEnumModels$.next(JSON.parse(localStorage.getItem('commonEnumModels')));
        } else {
            const payload = {
                Controller: 'EnumerationModel',
                Method: 'GetCommonEnumModels',
                RequestObject: {}
            };

            this.http.request(
                'post',
                environment.ApiUrl + Methods.MAIN_REQUEST, payload,
                false,
                null,
                true)
                .subscribe((res) => {
                    if (res?.ResponseCode === 0 && res.ResponseObject) {
                        localStorage.setItem('commonEnumModels', JSON.stringify(res.ResponseObject));
                        this.commonEnumModels$.next(res.ResponseObject);
                    }
                });
        }
    }

    public loadDocumentStatesEnumModels(): void {
        if (localStorage.getItem('documentStates')) {
            this.documentStates$.next(JSON.parse(localStorage.getItem('documentStates')));
        } else {
            const payload = {
                Controller: 'EnumerationModel',
                Method: 'GetDocumenStatesEnum',
                RequestObject: {}
            };

            this.http.request(
                'post',
                environment.ApiUrl + Methods.MAIN_REQUEST, payload,
                false,
                null,
                true)
                .subscribe(data => {
                    if (data.ResponseCode === 0 && data.ResponseObject) {
                        data.ResponseObject.map(status => {
                            status.Background = (status.Name === 'Approved' || status.Name === 'Won') ? '#79F2B8' :
                                status.Name === 'Lost' ? '#EE6464' : '#E3E3E8';

                            status.Color = (status.Name === 'Approved' || status.Name === 'Won' || status.Name === 'Lost') ? '#3F3F3F' : '#B3B3BC';
                        });
                        localStorage.setItem('documentStates', JSON.stringify(data.ResponseObject));
                        this.documentStates$.next(data.ResponseObject);
                    }
                });
        }
    }

    public loadDocumentTypesEnumModels(): void {
        if (localStorage.getItem('documentTypes')) {
            this.documentTypes$.next(JSON.parse(localStorage.getItem('documentTypes')));
        } else {
            const payload = {
                Controller: 'EnumerationModel',
                Method: 'GetClientDocumentTypesEnum',
                RequestObject: {}
            };

            this.http.request(
                'post',
                environment.ApiUrl + Methods.MAIN_REQUEST, payload,
                false,
                null,
                true)
                .subscribe((res) => {
                    if (res?.ResponseCode === 0 && res.ResponseObject) {
                        localStorage.setItem('documentTypes', JSON.stringify(res.ResponseObject));
                        this.documentTypes$.next(res.ResponseObject);
                    }
                });
        }
    }

    public getPlayers(filterOptions: any): Observable<any> {
        const payload = {
            Controller: 'Client',
            Method: 'GetClients',
            RequestObject: {
                ...filterOptions,
                filterModel: {}
            }
        };
        return this.http.request('post', environment.ApiUrl + Methods.MAIN_REQUEST, payload);
    }

    public getPlayerById(id: number): Observable<IResponseData<any>> {
        const payload = {
            Controller: 'Client',
            Method: 'GetClientById',
            RequestObject: id
        };
        return this.http.request('post', environment.ApiUrl + Methods.MAIN_REQUEST, payload);
    }

    public loadGameProviders(): void {
        if (localStorage.getItem('gameProviders')) {
            this.gameProviders$.next(JSON.parse(localStorage.getItem('gameProviders')));
        } else {
            this.getGameProviders()
                .subscribe(data => {
                    if (data.ResponseCode === 0 && data.ResponseObject) {
                        localStorage.setItem('gameProviders', JSON.stringify(data.ResponseObject));
                        this.gameProviders$.next(data.ResponseObject);
                    }
                });
        }
    }

    public getGameProviders(): Observable<IResponseData<IGameProvider[]>> {
        const payload = {
            Controller: 'Product',
            Method: 'GetGameProviders',
            RequestObject: {}
        };
        return this.http.request(
            'post',
            environment.ApiUrl + Methods.MAIN_REQUEST, payload);
    }

    private loadNotificationStates(): void {
        if (localStorage.getItem('notificationStates')) {
            this.notificationStates$.next(JSON.parse(localStorage.getItem('notificationStates')));
        } else {
            localStorage.setItem('notificationStates', JSON.stringify(this.notificationStates$.getValue()));
        }
    }
}
