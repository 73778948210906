import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ICellRendererParams } from 'ag-grid-community';

import { ILanguage } from '@core/interfaces';

@Injectable({
    providedIn: 'root'
})
export class LanguageService {

    public langList: ILanguage[] = [
        {
            label: 'English',
            value: {
                id: 'en',
                name: 'English',
            }
        },
        {
            label: 'فارسی',
            value: {
                id: 'fa',
                name: 'فارسی',
            }
        },
        {
            label: 'Türkçe',
            value: {
                id: 'tr',
                name: 'Türkçe',
            }
        },
    ];

    public langList$: BehaviorSubject<ILanguage[]> = new BehaviorSubject<ILanguage[]>(this.langList);
    public translationLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(
        private translateService: TranslateService,
    ) {
    }

    init(): void {
        const lang = localStorage.getItem('lang') || 'en';
        this.translateService.setDefaultLang(lang);
        this.setLanguage(lang);
    }

    public setLanguage(language: string): void {
        this.translateService.setDefaultLang(language);
        this.translateService.use(language)
            .subscribe(() => this.translationLoaded$.next(true));
        localStorage.setItem('lang', language);
    }

    public localizeTableHeader(translationKey: string): string {
        return this.translateService.instant(translationKey);
    }
}
