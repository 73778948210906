import { APP_INITIALIZER, NgModule, Provider } from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { TranslateModule } from '@ngx-translate/core';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DropdownModule } from 'primeng/dropdown';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { InputTextModule } from 'primeng/inputtext';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MessageService } from 'primeng/api';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';

import { AppMenuComponent } from './components/menu/app.menu.component';
import { AppMenuitemComponent } from './components/menu-item/app.menuitem.component';
import { AppTopBarComponent } from './components/topbar/app.topbar.component';
import {
    AppFooterComponent,
    AppMainComponent,
    AppConfigComponent,
    AppQuickSearchComponent,
} from '@core/components';
import { AppSearchComponent } from './components/search/app.search.component';
import { AppRightmenuComponent } from './components/rightmenu/app.rightmenu.component';
import { ConfigService } from '@core/services';
import { HttpInterceptor } from '@core/interceptors/http.interceptor';


export function initConfig(config: ConfigService): object {
    return () => config.load();
}

export async function getRecaptchaKey(config: ConfigService): Promise<string> {
    return await config.getReCaptchaKey();
}

const INTERCEPTOR_PROVIDER: Provider = {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpInterceptor,
    multi: true
};

@NgModule({
    declarations: [
        AppTopBarComponent,
        AppMenuComponent,
        AppMenuitemComponent,
        AppFooterComponent,
        AppMainComponent,
        AppConfigComponent,
        AppSearchComponent,
        AppRightmenuComponent,
        AppQuickSearchComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        TranslateModule.forChild(),
        RecaptchaV3Module,
        RadioButtonModule,
        DropdownModule,
        BreadcrumbModule,
        ButtonModule,
        RippleModule,
        InputTextModule,
        ReactiveFormsModule,
        InputSwitchModule,
    ],
    exports: [
        AppTopBarComponent,
        AppMenuComponent,
        AppMenuitemComponent,
        AppFooterComponent,
        AppMainComponent,
        AppConfigComponent,
        AppSearchComponent,
        AppRightmenuComponent,
        AppQuickSearchComponent,
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: initConfig,
            deps: [ConfigService],
            multi: true,
        },
        { provide: RECAPTCHA_V3_SITE_KEY, useFactory: getRecaptchaKey, deps: [ConfigService] },
         MessageService,
        INTERCEPTOR_PROVIDER,
        TitleCasePipe,

    ]
})
export class CoreModule {
}
