import {Component, ViewChild, ElementRef} from '@angular/core';
import {animate, state, style, transition, trigger, AnimationEvent} from '@angular/animations';
import { AppMainComponent } from '@core/components';

@Component({
    /* tslint:disable:component-selector */
    selector: 'app-search',
    /* tslint:enable:component-selector */
    templateUrl: './app.search.component.html',
    animations: [
        trigger('animation', [
            state('hidden', style({
                transform: 'translateY(20px)',
                opacity: 0,
                visibility: 'hidden'
            })),
            state('visible', style({
                opacity: 1,
                visibility: 'visible'
            })),
            transition('hidden <=> visible', animate('.4s cubic-bezier(.05,.74,.2,.99)'))
        ])
    ]
})
export class AppSearchComponent {

    @ViewChild('input') inputElement: ElementRef;

    constructor(public appMain: AppMainComponent) {}

    onAnimationEnd(event: AnimationEvent): void {
        if (event.toState === 'visible') {
            this.inputElement.nativeElement.focus();
        }
    }

    onInputKeydown(event): void {
        const key = event.which;

        // escape, tab and enter
        if (key === 27 || key === 9 || key === 13) {
            this.appMain.onSearchHide(event);
        }
    }
}
