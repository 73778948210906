<div class="layout-topbar">
    <div class="topbar-left">
        <a tabindex="0" class="menu-button" (click)="appMain.onMenuButtonClick($event)">
            <i class="pi icon icon-arrow_left"></i>
        </a>
        <img id="logo-horizontal" class="horizontal-logo" [routerLink]="['/']"
             src="assets/layout/images/logo-white.svg" alt="logo" />
        <span class="topbar-separator"></span>

        <p-breadcrumb [model]="breadcrumbItems"></p-breadcrumb>

        <img
            id="logo-mobile"
            class="mobile-logo"
            [src]="configService.colorScheme === 'light' ? configService.selectedTheme?.SecondaryShortLogo : configService.selectedTheme?.PrimaryShortLogo"
            alt="logo" />
    </div>

    <app-menu></app-menu>

    <div class="layout-mask modal-in"></div>

    <div class="topbar-right">
        <ul class="topbar-menu">
<!--            <li class="search-item">-->
<!--                <a tabindex="0" (click)="appMain.onSearchClick($event)">-->
<!--                    <i class="pi icon icon-search"></i>-->
<!--                </a>-->
<!--            </li>-->
<!--            <li class='notifications-item' [ngClass]="{'active-menuitem ': appMain.topbarNotificationMenuActive}">-->
<!--                <a href="#" tabindex="0" (click)="appMain.onTopbarNotificationMenuButtonClick($event)">-->
<!--                    <i class="pi icon icon-notifications"></i>-->
<!--                    <span class="topbar-badge">5</span>-->
<!--                </a>-->
<!--                <ul class="notifications-menu fade-in-up">-->
<!--                    <li role="menuitem">-->
<!--                        <a href="#" tabindex="0">-->
<!--                            <i class="pi icon pi-shopping-cart"></i>-->
<!--                            <div class="notification-item">-->
<!--                                <div class="notification-summary">New Order</div>-->
<!--                                <div class="notification-detail">You have <strong>3</strong> new orders.</div>-->
<!--                            </div>-->
<!--                        </a>-->
<!--                    </li>-->
<!--                    <li role="menuitem">-->
<!--                        <a href="#" tabindex="0">-->
<!--                            <i class="pi icon pi-check-square"></i>-->
<!--                            <div class="notification-item">-->
<!--                                <div class="notification-summary">Withdrawn Completed</div>-->
<!--                                <div class="notification-detail">Funds are on their way.</div>-->
<!--                            </div>-->
<!--                        </a>-->
<!--                    </li>-->
<!--                    <li role="menuitem">-->
<!--                        <a href="#" tabindex="0">-->
<!--                            <i class="pi icon pi-chart-line"></i>-->
<!--                            <div class="notification-item">-->
<!--                                <div class="notification-summary">Monthly Reports</div>-->
<!--                                <div class="notification-detail">New reports are ready.</div>-->
<!--                            </div>-->
<!--                        </a>-->
<!--                    </li>-->
<!--                    <li role="menuitem">-->
<!--                        <a href="#" tabindex="0">-->
<!--                            <i class="pi icon pi-comments"></i>-->
<!--                            <div class="notification-item">-->
<!--                                <div class="notification-summary">Comments</div>-->
<!--                                <div class="notification-detail"><strong>2</strong> new comments.</div>-->
<!--                            </div>-->
<!--                        </a>-->
<!--                    </li>-->
<!--                    <li role="menuitem">-->
<!--                        <a href="#" tabindex="0">-->
<!--                            <i class="pi icon pi-exclamation-circle"></i>-->
<!--                            <div class="notification-item">-->
<!--                                <div class="notification-summary">Chargeback Request</div>-->
<!--                                <div class="notification-detail"><strong>1</strong> to review.</div>-->
<!--                            </div>-->
<!--                        </a>-->
<!--                    </li>-->
<!--                </ul>-->
<!--            </li>-->

            <li class="profile-item" [ngClass]="{'active-menuitem fadeInDown': appMain.topbarUserMenuActive}" *ngIf="authService.userData$ | async as user">
                <a href="#" (click)="appMain.onTopbarUserMenuButtonClick($event)">
                    <i class="pi icon icon-user"></i>
                    <span class="profile-name">{{user.UserName}}</span>
                </a>
                <ul class="profile-menu fade-in-up">
                    <li>
                        <a>
                            <p-dropdown
                                (onClick)="$event.stopPropagation()"
                                [options]="languageService.langList$ | async"
                                [(ngModel)]="selectedLang"
                                placeholder="{{'Select language' | translate}}"
                                (onChange)="changeLanguage($event.value.id)">
                            </p-dropdown>
                        </a>
                    </li>
                    <li (click)="$event.stopPropagation()">
                        <a>
<!--                            <i class="pi icon pi-sun"></i>-->
                            <i class="pi icon pi-moon"></i>
                            <span class="profile-meu-item">Dark mode</span>
                            <p-inputSwitch
                                [(ngModel)]="darkModeActive"
                                (onChange)="changeTheme($event)"
                            ></p-inputSwitch>
                        </a>
                    </li>
                    <li>
                        <a>
                            <i class="pi icon pi-user"></i>
                            <span class="profile-meu-item">Profile</span>
                        </a>
                    </li>
                    <li>
                        <a>
                            <i class="pi icon pi-cog"></i>
                            <span class="profile-meu-item">Settings</span>
                        </a>
                    </li>
                    <li>
                        <a>
                            <i class="pi icon pi-calendar"></i>
                            <span class="profile-meu-item">Calendar</span>
                        </a>
                    </li>
                    <li>
                        <a>
                            <i class="pi icon pi-inbox"></i>
                            <span class="profile-meu-item">Inbox</span>
                        </a>
                    </li>
                    <li *ngIf="authService.isAuthenticated$ | async" (click)="authService.logout()">
                        <a>
                            <i class="pi icon pi-power-off"></i>
                            <span class="profile-meu-item">{{'Logout' | translate}}</span>
                        </a>
                    </li>
                </ul>
            </li>

<!--            <li class="right-sidebar-item" aria-disabled="true">-->
<!--                <a tabindex="0" (click)="appMain.onRightMenuClick($event)" >-->
<!--                    <i class="pi icon icon-filter_filled"></i>-->
<!--                </a>-->
<!--            </li>-->
        </ul>
    </div>
</div>
