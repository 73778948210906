import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class QuickSearchService {
    public quickSearchActive: boolean = false;

    constructor() {
    }
}
