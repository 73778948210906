import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '@env/environment';

import { Methods } from '@core/enums';
import { IResponseData } from '@core/interfaces';
import { AuthService, HttpService } from '@core/services';
import { ICellRendererParams } from 'ag-grid-community';

@Injectable({
    providedIn: 'root'
})
export class PaymentsService {
    public paymentRequestStates$: BehaviorSubject<any> = new BehaviorSubject<any>([]);

    constructor(
        private http: HttpService,
        private authService: AuthService,
    ) {
        this.loadPaymentRequestsStates();
    }

    public getGgr(ClientId: number): Observable<IResponseData<number>> {
        const payload = {
            Controller: 'Client',
            Method: 'GetClientGGR',
            ClientId,
            RequestObject: {}
        };
        const queryParams = {
            TimeZone: 4.5,
            LanguageId: 'en'
        };
        return this.http.request(
            'post',
            environment.ApiUrl + Methods.MAIN_REQUEST,
            payload,
            false,
            {params: queryParams});
    }

    /*
    TODO - remove FromDate and ToDate fields
     */
    public getPaymentRequests(filterOptions: any, paymentRequestType: number): Observable<any> {
        const payload = {
            Controller: 'PaymentSystem',
            Method: 'GetPaymentRequestsPaging',
            RequestObject: {
                ...filterOptions,
                Type: paymentRequestType,
                filterModel: {}
            }
        };
        return this.http.request('post', environment.ApiUrl + Methods.MAIN_REQUEST, payload);
    }

    public loadPaymentRequestsStates(): void {
        if (localStorage.getItem('paymentRequestsStates')) {
            this.paymentRequestStates$.next(JSON.parse(localStorage.getItem('paymentRequestsStates')));
        } else {
            const payload = {
                Controller: 'EnumerationModel',
                Method: 'GetPaymentRequestStatesEnum',
                RequestObject: {}
            };

            this.http.request(
                'post',
                environment.ApiUrl + Methods.MAIN_REQUEST,
                payload,
                false,
                null,
                true)
                .subscribe(data => {
                    if (data.ResponseObject) {
                        data.ResponseObject.map(status => {
                            status.Background = (status.Name === 'Approved'
                                || status.Name === 'Approved Manually'
                                || status.Name === 'Confirmed') ? '#79F2B8' :
                                (status.Name === 'Cancelled By Client'
                                    || status.Name === 'CancelledByUser'
                                    || status.Name === 'Failed') ? '#FFD6DA' :
                                    (status.Name === 'Pending'
                                        || status.Name === 'WaitingForKYC'
                                        || status.Name === 'Pay Pending'
                                        || status.Name === 'InProcess'
                                        || status.Name === 'CheckName'
                                        || status.Name === 'Frozen') ? '#E3E3E8' :
                                        status.Name === 'Deleted' ? '#EE6464' : '#E3E3E8';

                            status.Color = (status.Name === 'Approved'
                                || status.Name === 'Approved Manually'
                                || status.Name === 'Confirmed') ? '#3F3F3F' :
                                (status.Name === 'Cancelled By Client'
                                    || status.Name === 'CancelledByUser'
                                    || status.Name === 'Failed') ? '#3F3F3F' :
                                    (status.Name === 'Pending'
                                        || status.Name === 'WaitingForKYC'
                                        || status.Name === 'Pay Pending'
                                        || status.Name === 'InProcess'
                                        || status.Name === 'CheckName'
                                        || status.Name === 'Frozen') ? '#B3B3BC' :
                                        status.Name === 'Deleted' ? '#3F3F3F' : '#B3B3BC';
                        });
                        localStorage.setItem('paymentRequestsStates', JSON.stringify(data.ResponseObject));
                        this.paymentRequestStates$.next(data.ResponseObject);
                    }
                });
        }
    }

    public getPaymentRequestById(id: number): Observable<IResponseData<any>> {
        const payload = {
            Controller: 'PaymentSystem',
            Method: 'GetPaymentRequestById',
            RequestObject: id
        };
        return this.http.request('post', environment.ApiUrl + Methods.MAIN_REQUEST, payload);
    }

    public updatePaymentRequestStatus(paymentRequestId: number,
                                      status: number,
                                      method: string): Observable<IResponseData<any>> {
        const payload = {
            Controller: 'PaymentSystem',
            Method: method,
            RequestObject: {
                Parameters: '{}',
                PaymentRequestId: paymentRequestId,
                RequestType: status
            }
        };
        return this.http.request('post', environment.ApiUrl + Methods.MAIN_REQUEST, payload);
    }

    public getNumberRoundingOption(params: ICellRendererParams): string {
        const points = (params.data.IsTotal || !params.data.IsTotal && !params.data.Points) ?
            this.authService.userData$.getValue().Points : params.data.Points;
        return `.${points}-${points}`;
    }

}
