import { Component } from '@angular/core';
import { BreadcrumbService, ConfigService } from '@core/services';

@Component({
    templateUrl: './app.invoice.component.html'
})
export class AppInvoiceComponent {

    constructor(
        public configService: ConfigService,
        private breadcrumbService: BreadcrumbService,
    ) {
        this.breadcrumbService.setItems([
            {label: 'Invoice'}
        ]);
    }

    print() {
        window.print();
    }
}
