import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';

import { AppMainComponent } from '@core/components';
import { ConfigService } from '@core/services';
import { IMenuItems } from '@core/interfaces';
import { environment } from '@env/environment';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html',
})
export class AppMenuComponent implements OnInit {

    public model$: Observable<IMenuItems[]>;
    private model: IMenuItems[];

    constructor(
        public appMain: AppMainComponent,
        public configService: ConfigService,
    ) {

    }

    ngOnInit(): void {

        this.model = [
            {
                items: [
                    { label: 'Dashboard', icon: 'pi pi-fw icon-dashboard', routerLink: ['/'] },
                    // {label: 'Bet Monitor', icon: 'pi pi-fw icon-bet_monitor', routerLink: ['/betmonitor']},
                    // {label: 'Real Time', icon: 'pi pi-fw icon-real_time', routerLink: ['/realtime']},
                    {
                        label: 'Payments',
                        icon: 'pi pi-fw icon-payments',
                        items: [
                            { label: 'Deposits', routerLink: ['/payments', 'deposits'] },
                            { label: 'Withdrawals', routerLink: ['/payments', 'withdrawals'] },
                        ]
                    },
                    { label: 'Bets', icon: 'pi pi-fw icon-bets', routerLink: ['/bets'] },
                    // {
                    //     label: 'Bonuses',
                    //     icon: 'pi pi-fw icon-bonuses',
                    //     items: [
                    //         {label: 'Common', routerLink: ['/bonuses/common']},
                    //         {label: 'Free Spins', routerLink: ['/bonuses/free-spins']},
                    //         {label: 'Triggers', routerLink: ['/bonuses/triggers']},
                    //     ]
                    // },
                    { label: 'Players', icon: 'pi pi-fw icon-user', routerLink: ['/players'] },
                    // {label: 'Player Categories', icon: 'pi pi-fw icon-clients-categories', routerLink: ['/playercategories']},
                    { label: 'Partners', icon: 'pi pi-fw icon-partners', routerLink: ['/partners'] },
                    { label: 'Users', icon: 'pi pi-fw icon-users', routerLink: ['/users'] },
                    {
                        label: 'Reports',
                        icon: 'pi pi-fw icon-reports',
                        items: [
                            { label: 'ReportByPartners', routerLink: ['/reports', 'report-by-partners'] },
                            { label: 'ReportByProviders', routerLink: ['/reports', 'report-by-providers'] },
                            { label: 'MultiAccountDetection', routerLink: ['/reports', 'multi-account-detection'] },
                            {
                                label: 'MADIdentificationCount',
                                routerLink: ['/reports', 'multi-account-identification-count']
                            },
                        ]
                    },
                    {
                        label: 'Providers',
                        icon: 'pi pi-fw icon-providers',
                        routerLink: ['/providers']
                    },
                    {
                        label: 'Notifications',
                        icon: 'pi icon-notifications',
                        items: [
                            { label: 'Emails', routerLink: ['/notifications', 'emails'] },
                            { label: 'Smses', routerLink: ['/notifications', 'smses'] },
                        ]
                    },
                    // {label: 'Product Groups', icon: 'pi pi-fw icon-product_groups', routerLink: ['/productgroups']},
                    // {
                    //     label: 'Products',
                    //     icon: 'pi pi-fw icon-products',
                    //     secondaryIcon: 'pi pi-fw icon-settings',
                    //     secondaryRouterLink: ['/bonuses/common'],
                    //     items: [
                    //         {label: 'Common', routerLink: ['/bonuses/common']},
                    //         {label: 'Free Spins', routerLink: ['/bonuses/free-spins']},
                    //         {label: 'Triggers', routerLink: ['/bonuses/triggers']},
                    //     ]
                    // },
                    // {
                    //     label: 'Reports',
                    //     icon: 'pi pi-fw icon-reports',
                    //     items: [
                    //         {
                    //             label: 'Internet Players',
                    //             items: [
                    //                 {label: 'Report By Bets', routerLink: ['/bonuses/common']},
                    //                 {label: 'Report By Games', routerLink: ['/bonuses/common']},
                    //                 {label: 'Report By Providers', routerLink: ['/bonuses/common']},
                    //             ]
                    //         },
                    //         {
                    //             label: 'Business Intelligence',
                    //             items: [
                    //                 {label: 'Report By Bets', routerLink: ['/bonuses/common']},
                    //                 {label: 'Report By Games', routerLink: ['/bonuses/common']},
                    //                 {label: 'Report By Providers', routerLink: ['/bonuses/common']},
                    //             ]
                    //         },
                    //     ]
                    // },
                    // {label: 'Roles', icon: 'pi pi-fw icon-roles', routerLink: ['/roles']},
                    // {label: 'Currencies', icon: 'pi pi-fw icon-currencies', routerLink: ['/currencies']},
                    // {
                    //     label: 'Notifications',
                    //     icon: 'pi pi-fw icon-notifications',
                    //     items: [
                    //         {label: 'Tickets', routerLink: ['/notifications/tickets']},
                    //         {label: 'Emails', routerLink: ['/notifications/emails']},
                    //         {label: 'Smses', routerLink: ['/notifications/smses']},
                    //         {label: 'Announcements', routerLink: ['/notifications/announcements']},
                    //     ]
                    // },
                    // {
                    //     label: 'CRM',
                    //     icon: 'pi pi-fw icon-CRM',
                    //     items: [
                    //         {label: 'Settings', routerLink: ['/crm/settings']},
                    //         {label: 'Templates', routerLink: ['/notifications/templates']},
                    //     ]
                    // },
                    // {
                    //     label: 'CMS',
                    //     icon: 'pi pi-fw icon-CMS',
                    //     items: [
                    //         {label: 'Banners', routerLink: ['/cms/banners']},
                    //         {label: 'Regions', routerLink: ['/cms/regions']},
                    //         {label: 'Comment Types', routerLink: ['/cms/comment-types']},
                    //     ]
                    // },
                ]
            },
        ];

        if (!environment.production) {
            const developmentMenu = [
                { separator: true },
                {
                    label: 'UI Kit', icon: 'pi pi-fw pi-star-fill', routerLink: ['/uikit'],
                    items: [
                        { label: 'Form Layout', icon: 'pi pi-fw pi-id-card', routerLink: ['/uikit/formlayout'] },
                        { label: 'Input', icon: 'pi pi-fw pi-check-square', routerLink: ['/uikit/input'] },
                        { label: 'Float Label', icon: 'pi pi-fw pi-bookmark', routerLink: ['/uikit/floatlabel'] },
                        {
                            label: 'Invalid State',
                            icon: 'pi pi-fw pi-exclamation-circle',
                            routerLink: ['/uikit/invalidstate']
                        },
                        {
                            label: 'Button',
                            icon: 'pi pi-fw pi-mobile',
                            routerLink: ['/uikit/button'],
                            class: 'rotated-icon'
                        },
                        { label: 'AG Table', icon: 'pi pi-fw pi-table', routerLink: ['/ag-table'] },
                        { label: 'Table', icon: 'pi pi-fw pi-table', routerLink: ['/uikit/table'] },
                        { label: 'List', icon: 'pi pi-fw pi-list', routerLink: ['/uikit/list'] },
                        { label: 'Tree', icon: 'pi pi-fw pi-share-alt', routerLink: ['/uikit/tree'] },
                        { label: 'Panel', icon: 'pi pi-fw pi-tablet', routerLink: ['/uikit/panel'] },
                        { label: 'Overlay', icon: 'pi pi-fw pi-clone', routerLink: ['/uikit/overlay'] },
                        { label: 'Media', icon: 'pi pi-fw pi-image', routerLink: ['/uikit/media'] },
                        { label: 'Menu', icon: 'pi pi-fw pi-bars', routerLink: ['/uikit/menu'], preventExact: true },
                        { label: 'Message', icon: 'pi pi-fw pi-comment', routerLink: ['/uikit/message'] },
                        { label: 'File', icon: 'pi pi-fw pi-file', routerLink: ['/uikit/file'] },
                        { label: 'Chart', icon: 'pi pi-fw pi-chart-bar', routerLink: ['/uikit/charts'] },
                        { label: 'Misc', icon: 'pi pi-fw pi-circle-off', routerLink: ['/uikit/misc'] }
                    ]
                },
                { separator: true },
                {
                    label: 'Prime Blocks', icon: 'pi pi-fw pi-prime', routerLink: ['/blocks'],
                    items: [
                        { label: 'Free Blocks', icon: 'pi pi-fw pi-eye', routerLink: ['/blocks'] },
                        {
                            label: 'All Blocks',
                            icon: 'pi pi-fw pi-globe',
                            url: ['https://www.primefaces.org/primeblocks-ng'],
                            target: '_blank'
                        },
                    ]
                },
                { separator: true },
                {
                    label: 'Utilities', icon: 'pi pi-fw pi-compass', routerLink: ['/utilities'],
                    items: [
                        { label: 'PrimeIcons', icon: 'pi pi-fw pi-prime', routerLink: ['utilities/icons'] },
                        {
                            label: 'PrimeFlex',
                            icon: 'pi pi-fw pi-desktop',
                            url: ['https://www.primefaces.org/primeflex/'],
                            target: '_blank'
                        },
                    ]
                },
                { separator: true },
                {
                    label: 'Pages', icon: 'pi pi-fw pi-briefcase', routerLink: ['/pages'],
                    items: [
                        { label: 'Crud', icon: 'pi pi-fw pi-pencil', routerLink: ['/pages/crud'] },
                        { label: 'Calendar', icon: 'pi pi-fw pi-calendar-plus', routerLink: ['/pages/calendar'] },
                        { label: 'Timeline', icon: 'pi pi-fw pi-calendar', routerLink: ['/pages/timeline'] },
                        {
                            label: 'Landing',
                            icon: 'pi pi-fw pi-globe',
                            url: 'assets/pages/landing.html',
                            target: '_blank'
                        },
                        { label: 'Login', icon: 'pi pi-fw pi-sign-in', routerLink: ['/login'] },
                        { label: 'Invoice', icon: 'pi pi-fw pi-dollar', routerLink: ['/pages/invoice'] },
                        { label: 'Help', icon: 'pi pi-fw pi-question-circle', routerLink: ['/pages/help'] },
                        { label: 'Error', icon: 'pi pi-fw pi-times-circle', routerLink: ['/error'] },
                        { label: 'Not Found', icon: 'pi pi-fw pi-exclamation-circle', routerLink: ['/notfound'] },
                        { label: 'Access Denied', icon: 'pi pi-fw pi-lock', routerLink: ['/access'] },
                        { label: 'Empty', icon: 'pi pi-fw pi-circle-off', routerLink: ['/pages/empty'] }
                    ]
                },
                { separator: true },
                {
                    label: 'Hierarchy', icon: 'pi pi-fw pi-align-left',
                    items: [
                        {
                            label: 'Submenu 1', icon: 'pi pi-fw pi-align-left',
                            items: [
                                {
                                    label: 'Submenu 1.1', icon: 'pi pi-fw pi-align-left',
                                    items: [
                                        { label: 'Submenu 1.1.1', icon: 'pi pi-fw pi-align-left' },
                                        { label: 'Submenu 1.1.2', icon: 'pi pi-fw pi-align-left' },
                                        { label: 'Submenu 1.1.3', icon: 'pi pi-fw pi-align-left' },
                                    ]
                                },
                                {
                                    label: 'Submenu 1.2', icon: 'pi pi-fw pi-align-left',
                                    items: [
                                        { label: 'Submenu 1.2.1', icon: 'pi pi-fw pi-align-left' }
                                    ]
                                },
                            ]
                        },
                        {
                            label: 'Submenu 2', icon: 'pi pi-fw pi-align-left',
                            items: [
                                {
                                    label: 'Submenu 2.1', icon: 'pi pi-fw pi-align-left',
                                    items: [
                                        { label: 'Submenu 2.1.1', icon: 'pi pi-fw pi-align-left' },
                                        { label: 'Submenu 2.1.2', icon: 'pi pi-fw pi-align-left' },
                                    ]
                                },
                                {
                                    label: 'Submenu 2.2', icon: 'pi pi-fw pi-align-left',
                                    items: [
                                        { label: 'Submenu 2.2.1', icon: 'pi pi-fw pi-align-left' },
                                    ]
                                },
                            ]
                        }
                    ]
                },
                { separator: true },
                {
                    label: 'Start', icon: 'pi pi-fw pi-download',
                    items: [
                        {
                            label: 'Buy Now',
                            icon: 'pi pi-fw pi-shopping-cart',
                            url: ['https://www.primefaces.org/store']
                        },
                        {
                            label: 'Documentation', icon: 'pi pi-fw pi-info-circle', routerLink: ['/documentation']
                        }
                    ]
                },
            ];

            this.model.push(...developmentMenu);
        }

        this.model$ = of(this.model);
    }
}
