import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MenuItem } from 'primeng/api';

import { AuthService, BreadcrumbService, ConfigService, LanguageService } from '@core/services';
import { AppMainComponent } from '@core/components';
import { AppConfig } from '@app/demo/domain/appconfig';

@Component({
    selector: 'app-topbar',
    styleUrls: ['./app.topbar.component.scss'],
    templateUrl: './app.topbar.component.html'
})

export class AppTopBarComponent implements OnDestroy {
    public breadcrumbItems: MenuItem[];
    public  selectedLang: { [key: string]: string };

    public config: AppConfig;
    public darkModeActive: boolean = false;

    private unsubscribe$: Subject<void> = new Subject<void>();
    constructor(
        public breadcrumbService: BreadcrumbService,
        public configService: ConfigService,
        public appMain: AppMainComponent,
        public languageService: LanguageService,
        public authService: AuthService,
    ) {
        this.changeColorScheme(configService.colorScheme);

        this.breadcrumbService.breadcrumbItems$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(response => {
                this.breadcrumbItems = response;
            });
        this.selectedLang =
            this.languageService.langList$.getValue()
                .find(lang => lang.value.id === (localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en')).value;

        this.config = this.configService.config;
        this.darkModeActive = this.configService.colorScheme === 'dim';
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public changeLanguage(lang: string): void {
        this.languageService.setLanguage(lang);
        this.appMain.onTopbarUserMenuButtonClick();
    }

    public changeColorScheme(scheme): void {
        if (scheme === 'light') {
            this.configService.updateConfig({...this.config, ...{dark: false}});
        } else {
            this.configService.updateConfig({...this.config, ...{dark: true}});
        }
        this.configService.colorScheme = scheme;
        localStorage.setItem('selectedTheme', scheme);
        this.changeStyleSheetsColor('layout-css', 'layout-' + scheme + '.css', 1);
        this.changeStyleSheetsColor('theme-css', 'theme-' + scheme + '.css', 1);
        this.configService.setRootColorVariables();
    }

    public changeTheme(e): void {
        e.originalEvent.stopPropagation();
        const scheme = e.checked ? 'dim' : 'light';

        if (scheme === 'light') {
            this.configService.updateConfig({...this.config, ...{dark: false}});
        } else {
            this.configService.updateConfig({...this.config, ...{dark: true}});
        }
        this.configService.colorScheme = scheme;
        localStorage.setItem('selectedTheme', scheme);
        this.changeStyleSheetsColor('layout-css', 'layout-' + scheme + '.css', 1);
        this.changeStyleSheetsColor('theme-css', 'theme-' + scheme + '.css', 1);
        this.configService.setRootColorVariables();
    }

    private changeStyleSheetsColor(id, value, from): void {
        const element = document.getElementById(id);
        const urlTokens = element.getAttribute('href').split('/');

        if (from === 1) {           // which function invoked this function
            urlTokens[urlTokens.length - 1] = value;
        } else if (from === 2) {       // which function invoked this function
            if (value !== null) {
                urlTokens[urlTokens.length - 2] = value;
            }
        } else if (from === 3) {       // which function invoked this function
            urlTokens[urlTokens.length - 2] = value;
        }

        const newURL = urlTokens.join('/');

        this.replaceLink(element, newURL);
    }

    private replaceLink(linkElement, href): void {
        if (this.isIE()) {
            linkElement.setAttribute('href', href);
        } else {
            const id = linkElement.getAttribute('id');
            const cloneLinkElement = linkElement.cloneNode(true);

            cloneLinkElement.setAttribute('href', href);
            cloneLinkElement.setAttribute('id', id + '-clone');

            linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

            cloneLinkElement.addEventListener('load', () => {
                linkElement.remove();
                cloneLinkElement.setAttribute('id', id);
            });
        }
    }

    private isIE(): boolean {
        return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent);
    }

}
