import { Injectable } from '@angular/core';
import { TitleCasePipe } from '@angular/common';
import { ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { filter } from 'rxjs/operators';

import { MenuItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

import { LanguageService } from '@core/services/language.service';

@Injectable({
    providedIn: 'root'
})
export class BreadcrumbService {
    private breadcrumbItemsSource$ = new BehaviorSubject<MenuItem[]>([]);
    public breadcrumbItems$ = this.breadcrumbItemsSource$.asObservable();

    constructor(
        private router: Router,
        private translateService: TranslateService,
        private languageService: LanguageService,
        private titleCasePipe: TitleCasePipe,
        ) {
        // Update the breadcrumb when page is loading for first time
        this.setBreadcrumb(this.router.routerState.snapshot.root, [], []);

        combineLatest([this.languageService.translationLoaded$, this.router.events.pipe(
            // Filter the NavigationEnd events as the breadcrumb is updated only when the route reaches its end
            filter((event) => event instanceof NavigationEnd))])
            .subscribe(() => {
                    this.setBreadcrumb(this.router.routerState.snapshot.root, [], []);
                }
            );

    }

    private addBreadcrumbItem(route: ActivatedRouteSnapshot, parentUrl: string[], breadcrumbItems: MenuItem[]): void {
        if (route) {
            // Construct the route URL
            const routeUrl = parentUrl.concat(route.url.map(url => url.path));

            // Add an element for the current route part
            if (route.data.breadcrumb) {
                if (route.data.breadcrumb.title) {
                    const breadcrumb = {
                        label: this.translateService.instant(route.data.breadcrumb?.title),
                        routerLink: `/${routeUrl.join('/')}`,
                        styleClass: route.data.breadcrumb?.navigate ? '' : 'pointer-events-none',
                    };

                    if ((breadcrumbItems.length && breadcrumbItems[breadcrumbItems.length - 1].label !== breadcrumb.label)
                        || !breadcrumbItems.length) {
                        breadcrumbItems.push(breadcrumb);
                    }
                } else if (route.data.breadcrumb.title === '') {
                    // If no routeConfig is available we are on the root path
                    const path = route.routeConfig && route.routeConfig.data ? route.routeConfig.path : '';
                    const separatedPath = path.split('/');
                    for (const pathValue of separatedPath) {
                        const isDynamicRoute = pathValue.startsWith(':');

                        if (isDynamicRoute) {
                            const paramName = pathValue.split(':')[1];
                            const breadcrumb = {
                                label: this.translateService.instant(this.titleCasePipe.transform(route.params[paramName])),
                                routerLink: `${parentUrl.join('/')}/${route.params[paramName]}`,
                            };
                            breadcrumbItems.push(breadcrumb);
                        }
                    }
                }
            }


            // Add another element for the next route part
            this.addBreadcrumbItem(route.firstChild, routeUrl, breadcrumbItems);
        }
    }

    public setBreadcrumb(route: ActivatedRouteSnapshot, parentUrl: string[], breadcrumbItems: MenuItem[]): void {
        this.addBreadcrumbItem(route, parentUrl, breadcrumbItems);
        this.breadcrumbItemsSource$.next(breadcrumbItems);
    }

    public setItems(items: MenuItem[]): void {
        this.breadcrumbItemsSource$.next(items);
    }
}
