import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(
      private translateService: TranslateService,
      private service: MessageService,

  ) { }

    public showToastMsg(severity: string, detail: string): void {
        this.service.add({
            key: 'tst', severity,
            detail
        });
    }
}
