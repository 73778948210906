<div class="quick-search-layout"
     [ngClass]="{ 'active': quickSearchService.quickSearchActive }">
    <button pButton
            icon="icon-fast-search"
            class="quick-search-button"
            [ngClass]="{ 'quick-search-button-active': quickSearchService.quickSearchActive }"
            (click)="onOpenQuickSearch()"
    ></button>

    <div class="quick-search-form">
        <form [formGroup]="quickSearchForm">
            <div formGroupName="Player">
                <h5 class="mb-1">{{ 'Player' | translate }}</h5>
                <hr class="mb-4 mt-2"/>
                <div class="formgroup-inline flex-nowrap">
                    <div class="field">
                        <input pInputText
                               type="text"
                               formControlName="PlayerId"
                               [placeholder]="'PlayerId' | translate"
                               (keyup.enter)="onQuickSearch('Player', 'PlayerId', 'players')"
                               class="quick-search-input"/>
                    </div>
                    <button pButton
                            [label]="'Go' | translate"
                            class="button-go"
                            (click)="onQuickSearch('Player', 'PlayerId', 'players')">
                    </button>
                </div>

                <div class="formgroup-inline flex-nowrap">
                    <div class="field">
                        <input pInputText
                               type="text"
                               formControlName="Username"
                               [placeholder]="'Username' | translate"
                               (keyup.enter)="onQuickSearch('Player', 'Username', 'players')"
                               class="quick-search-input"/>
                    </div>
                    <button pButton
                            [label]="'Go' | translate"
                            class="button-go"
                            (click)="onQuickSearch('Player', 'Username', 'players')">
                    </button>
                </div>

                <div class="formgroup-inline flex-nowrap">
                    <div class="field">
                        <input pInputText
                               type="text"
                               formControlName="Email"
                               [placeholder]="'Email' | translate"
                               (keyup.enter)="onQuickSearch('Player', 'Email', 'players')"
                               class="quick-search-input"/>
                    </div>
                    <button pButton
                            [label]="'Go' | translate"
                            class="button-go"
                            (click)="onQuickSearch('Player', 'Email', 'players')">
                    </button>
                </div>

                <div class="formgroup-inline flex-nowrap">
                    <div class="field">
                        <input pInputText
                               type="text"
                               formControlName="MobileNumber"
                               [placeholder]="'MobileNumber' | translate"
                               (keyup.enter)="onQuickSearch('Player', 'MobileNumber', 'players')"
                               class="quick-search-input"/>
                    </div>
                    <button pButton
                            [label]="'Go' | translate"
                            class="button-go"
                            (click)="onQuickSearch('Player', 'MobileNumber', 'players')">
                    </button>
                </div>
            </div>

            <div formGroupName="Document">
                <h5 class="mb-1">{{ 'Document' | translate }}</h5>
                <hr class="mb-4 mt-2"/>

                <div class="formgroup-inline flex-nowrap">
                    <div class="field">
                        <input pInputText
                               type="text"
                               formControlName="DepositId"
                               [placeholder]="'DepositId' | translate"
                               (keyup.enter)="onQuickSearch('Document', 'DepositId', 'payments/deposits')"
                               class="quick-search-input"/>
                    </div>
                    <button pButton
                            [label]="'Go' | translate"
                            class="button-go"
                            (click)="onQuickSearch('Document', 'DepositId', 'payments/deposits')">
                    </button>
                </div>

                <div class="formgroup-inline flex-nowrap">
                    <div class="field">
                        <input pInputText
                               type="text"
                               formControlName="WithdrawalId"
                               [placeholder]="'WithdrawalId' | translate"
                               (keyup.enter)="onQuickSearch('Document', 'WithdrawalId', 'payments/withdrawal')"
                               class="quick-search-input"/>
                    </div>
                    <button pButton
                            [label]="'Go' | translate"
                            class="button-go"
                            (click)="onQuickSearch('Document', 'WithdrawalId', 'payments/withdrawal')">
                    </button>
                </div>

                <div class="formgroup-inline flex-nowrap">
                    <div class="field">
                        <input pInputText
                               type="text"
                               formControlName="InternetBetId"
                               [placeholder]="'InternetBetId' | translate"
                               (keyup.enter)="onQuickSearch('Document', 'InternetBetId', 'bets')"
                               class="quick-search-input"/>
                    </div>
                    <button pButton
                            [label]="'Go' | translate" class="button-go"
                            (click)="onQuickSearch('Document', 'InternetBetId', 'bets')">
                    </button>
                </div>

                <div class="formgroup-inline flex-nowrap">
                    <div class="field">
                        <input pInputText
                               type="text"
                               formControlName="ExternalId"
                               [placeholder]="'BetExternalId' | translate"
                               (keyup.enter)="onQuickSearch('Document', 'ExternalId', 'bets')"
                               class="quick-search-input"/>
                    </div>
                    <button pButton
                            [label]="'Go' | translate"
                            class="button-go"
                            (click)="onQuickSearch('Document', 'ExternalId', 'bets')">
                    </button>
                </div>
            </div>
        </form>
    </div>

</div>
