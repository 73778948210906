import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot
} from '@angular/router';

import { AuthService } from '@core/services';

@Injectable({
    providedIn: 'root'
})
export class UnAuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authService: AuthService,
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (!this.authService.isAuthenticated$.getValue() || !this.authService.userData$.getValue()) {
            return true;
        }
        this.router.navigate(['/']);
        return false;
    }

}
