<div class="layout-dashboard">
	<div class="grid">
        <div class="col-12 md:col-6 xl:col-3">
            <div class="card info-card no-gutter widget-overview-box widget-overview-box-1">
                <div class="title">New Registered Players</div>
                <div class="content">
                    <div class="amount">120K</div>
                    <div class="percent positive">
                        <i class="icon-arrow_increase"></i>
                        25%
                    </div>
                </div>
            </div>
            </div>
        <div class="col-12 md:col-6 xl:col-3">
            <div class="card info-card no-gutter widget-overview-box widget-overview-box-2">
                <div class="title">Bets</div>
                <div class="content">
                    <div class="amount">40K</div>
                    <div class="percent positive">
                        <i class="icon-arrow_increase"></i>
                        34%
                    </div>
                </div>
            </div>
            </div>
        <div class="col-12 md:col-6 xl:col-3">
            <div class="card info-card no-gutter widget-overview-box widget-overview-box-3">
                <div class="title">Deposits</div>
                <div class="content">
                    <div class="amount">$ 440,291</div>
                    <div class="percent negative">
                        <i class="icon-arrow_increase"></i>
                        2%
                    </div>
                </div>
            </div>
            </div>
        <div class="col-12 md:col-6 xl:col-3">
            <div class="card info-card no-gutter widget-overview-box widget-overview-box-4">
                <div class="title">Withdrawals</div>
                <div class="content">
                    <div class="amount">$ 32,014</div>
                    <div class="percent info">
                        <i class="icon-arrow_increase"></i>
                        2%
                    </div>
                </div>
            </div>
            </div>
<!--		<div class="col-12 md:col-6 xl:col-3">-->
<!--			<div class="card no-gutter widget-overview-box widget-overview-box-1">-->
<!--                            <span class="overview-icon">-->
<!--                                <i class="pi pi-shopping-cart"></i>-->
<!--                            </span>-->
<!--				<span class="overview-title">Orders</span>-->
<!--				<div class="grid overview-detail">-->
<!--					<div class="col-6">-->
<!--						<div class="overview-number">640</div>-->
<!--						<div class="overview-subtext">Pending</div>-->
<!--					</div>-->
<!--					<div class="col-6">-->
<!--						<div class="overview-number">1420</div>-->
<!--						<div class="overview-subtext">Completed</div>-->
<!--					</div>-->
<!--				</div>-->
<!--			</div>-->
<!--		</div>-->
<!--		<div class="col-12 md:col-6 xl:col-3">-->
<!--			<div class="card no-gutter widget-overview-box widget-overview-box-2">-->
<!--                            <span class="overview-icon">-->
<!--                                <i class="pi pi-dollar"></i>-->
<!--                            </span>-->
<!--				<span class="overview-title">Revenue</span>-->
<!--				<div class="grid overview-detail">-->
<!--					<div class="col-6">-->
<!--						<div class="overview-number">$2,100</div>-->
<!--						<div class="overview-subtext">Expenses</div>-->
<!--					</div>-->
<!--					<div class="col-6">-->
<!--						<div class="overview-number">$9,640</div>-->
<!--						<div class="overview-subtext">Income</div>-->
<!--					</div>-->
<!--				</div>-->
<!--			</div>-->
<!--		</div>-->
<!--		<div class="col-12 md:col-6 xl:col-3">-->
<!--			<div class="card no-gutter widget-overview-box widget-overview-box-3">-->
<!--                            <span class="overview-icon">-->
<!--                                <i class="pi pi-users"></i>-->
<!--                            </span>-->
<!--				<span class="overview-title">Customers</span>-->
<!--				<div class="grid overview-detail">-->
<!--					<div class="col-6">-->
<!--						<div class="overview-number">8272</div>-->
<!--						<div class="overview-subtext">Active</div>-->
<!--					</div>-->
<!--					<div class="col-6">-->
<!--						<div class="overview-number">25402</div>-->
<!--						<div class="overview-subtext">Registered</div>-->
<!--					</div>-->
<!--				</div>-->
<!--			</div>-->
<!--		</div>-->
<!--		<div class="col-12 md:col-6 xl:col-3">-->
<!--			<div class="card no-gutter widget-overview-box widget-overview-box-4">-->
<!--                            <span class="overview-icon">-->
<!--                                <i class="pi pi-comment"></i>-->
<!--                            </span>-->
<!--				<span class="overview-title">Comments</span>-->
<!--				<div class="grid overview-detail">-->
<!--					<div class="col-6">-->
<!--						<div class="overview-number">12</div>-->
<!--						<div class="overview-subtext">New</div>-->
<!--					</div>-->
<!--					<div class="col-6">-->
<!--						<div class="overview-number">85</div>-->
<!--						<div class="overview-subtext">Responded</div>-->
<!--					</div>-->
<!--				</div>-->
<!--			</div>-->
<!--		</div>-->


		<div class="col-12 xl:col-6">
			<div class="card no-gutter orders h-full">
				<div class="card-header">
					<h4>GGR</h4>
<!--					<p-menu #menu [popup]="true" [model]="items"></p-menu>-->
<!--					<button pButton type="button" id="orders-button" icon="pi pi-search" class="p-button-text p-button-secondary"-->
<!--							(click)="menu.toggle($event)"></button>-->
				</div>

				<div class="grid">
					<div class="col-12">
						<div id="order-tabs-container" class="grid order-tabs">
							<div class="order-tab order-tab-new col-6 md:col-3"
								 (click)="changeDataset($event); chart.reinit()"
								 data-label="Total Bets" data-index="0" data-stroke="#BBDEFB"
								 data-fill="rgba(100, 181, 246, 0.2)">
<!--								<i class="pi pi-plus-circle"></i>-->
								<span class="order-label">Total Bets</span>
								<i class="stat-detail-icon icon-arrow-right-circle"></i>
								<img src="assets/demo/images/dashboard/graph-new.svg" alt="diamond-ng"/>
							</div>
							<div class="order-tab order-tab-completed col-6 md:col-3"
								 (click)="changeDataset($event); chart.reinit()"
								 data-label="Total Win" data-index="1" data-stroke="#C5CAE9"
								 data-fill="rgba(121, 134, 203, 0.2)">
<!--								<i class="pi pi-check-circle"></i>-->
								<span class="order-label">Total Win</span>
								<i class="stat-detail-icon icon-arrow-right-circle"></i>
								<img src="assets/demo/images/dashboard/graph-completed.svg" alt="diamond-ng"/>
							</div>
							<div class="order-tab order-tab-refunded col-6 md:col-3"
								 (click)="changeDataset($event); chart.reinit()"
								 data-label="Costs" data-index="2" data-stroke="#B2DFDB"
								 data-fill="rgba(224, 242, 241, 0.5)">
<!--								<i class="pi pi-refresh"></i>-->
								<span class="order-label">Costs</span>
								<i class="stat-detail-icon icon-arrow-right-circle"></i>
								<img src="assets/demo/images/dashboard/graph-refunded.svg" alt="diamond-ng"/>
							</div>
							<div class="order-tab order-tab-cancelled col-6 md:col-3"
								 (click)="changeDataset($event); chart.reinit()"
								 data-label="NGR" data-index="3" data-stroke="#B2EBF2"
								 data-fill="rgba(224, 247, 250, 0.5)">
<!--								<i class="pi pi-times-circle"></i>-->
								<span class="order-label">NGR</span>
								<i class="stat-detail-icon icon-arrow-right-circle"></i>
								<img src="assets/demo/images/dashboard/graph-cancelled.svg" alt="diamond-ng"/>
							</div>
						</div>
					</div>
					<div class="col-12">
						<div class="overview-chart">
							<p-chart #chart type="line" [data]="ordersChart" [options]="ordersChartOptions" id="order-chart"></p-chart>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="col-12 xl:col-6">
			<div class="card h-full">
				<div class="card-header">
					<h4>Financial statistics</h4>
					<p-dropdown [options]="orderWeek" [(ngModel)]="selectedOrderWeek" optionLabel="name" (onChange)="recentSales($event)"
								styleClass="dashboard-demo-dropdown" [ngStyle]="{'min-width': '8rem'}"></p-dropdown>
				</div>

<!--				<p>Your sales activity over time.</p>-->


				<p-table [value]="products" [paginator]="true" [rows]="6" styleClass="p-datatable-customers">
					<ng-template pTemplate="header">
						<tr>
							<th pSortableColumn="id">ID
								<p-sortIcon field="id"></p-sortIcon>
							</th>
							<th pSortableColumn="category">Category
								<p-sortIcon field="category"></p-sortIcon>
							</th>
							<th pSortableColumn="price">Amount
								<p-sortIcon field="price"></p-sortIcon>
							</th>
<!--							<th pSortableColumn="inventoryStatus">Status-->
<!--								<p-sortIcon field="inventoryStatus"></p-sortIcon>-->
<!--							</th>-->
<!--							<th></th>-->
						</tr>
					</ng-template>
					<ng-template pTemplate="body" let-product>
						<tr>
							<td>
								<span class="p-column-title">Id</span>
								{{product.id}}</td>
							<td>
								<span class="p-column-title">Category</span>
								{{product.category}}</td>
							<td>
								<span class="p-column-title">Price</span>
								{{product.price | currency:'USD'}}</td>
<!--							<td>-->
<!--								<span class="p-column-title">Status</span>-->
<!--								<span [class]="'product-badge status-' + product.inventoryStatus.toLowerCase()">{{product.inventoryStatus}}</span>-->
<!--							</td>-->
<!--							<td style="text-align: center">-->
<!--								<button pButton type="button" icon="pi pi-search"></button>-->
<!--							</td>-->
						</tr>
					</ng-template>
				</p-table>
			</div>
		</div>

		<div class="col-12 lg:col-4">
			<div class="card widget-tasks h-full">
				<h4>GGR by products</h4>
<!--				<p>Overview of your pending tasks.</p>-->
				<div>
					<div class="task task-1">
						<div class="task-name"><span>26K </span> Sportsbook</div>
						<div class="task-progress">
							<div></div>
						</div>
					</div>
					<div class="task task-5">
						<div class="task-name"><span>15K </span> Skill Games</div>
						<div class="task-progress">
							<div></div>
						</div>
					</div>
					<div class="task task-2">
						<div class="task-name"><span>20K</span> Casino</div>
						<div class="task-progress">
							<div></div>
						</div>
					</div>
					<div class="task task-3">
						<div class="task-name"><span>27K</span> Poker</div>
						<div class="task-progress">
							<div></div>
						</div>
					</div>
					<div class="task task-4">
						<div class="task-name"><span>13K</span> Virtual Games</div>
						<div class="task-progress">
							<div></div>
						</div>
					</div>
					<div class="task task-5">
						<div class="task-name"><span>10K</span> Betting Games</div>
						<div class="task-progress">
							<div></div>
						</div>
					</div>
					<div class="task task-6">
						<div class="task-name"><span>30K</span> Slots</div>
						<div class="task-progress">
							<div></div>
						</div>
					</div>
					<div class="task task-7">
						<div class="task-name"><span>20K</span> Live Games</div>
						<div class="task-progress">
							<div></div>
						</div>
					</div>
				</div>
			</div>

<!--			<div class="card">-->
<!--				<h4>Best Sellers</h4>-->
<!--				<p>Most popular products from your collection.</p>-->

<!--				<ul class="widget-image-list">-->
<!--					<li>-->
<!--						<span>Product</span>-->
<!--						<span>Sales</span>-->
<!--					</li>-->
<!--					<li>-->
<!--                        <span>-->
<!--                            <img src="assets/demo/images/product/bamboo-watch.jpg" alt="diamond-layout"/>-->
<!--                            <span>Bamboo Watch</span>-->
<!--                        </span>-->
<!--						<span class="listitem-value">82</span>-->
<!--					</li>-->
<!--					<li>-->
<!--                        <span>-->
<!--                            <img src="assets/demo/images/product/blue-band.jpg" alt="diamond-layout"/>-->
<!--                            <span>Blue Band</span>-->
<!--                        </span>-->
<!--						<span class="listitem-value">75</span>-->
<!--					</li>-->
<!--					<li>-->
<!--                        <span>-->
<!--                            <img src="assets/demo/images/product/game-controller.jpg" alt="diamond-layout"/>-->
<!--                            <span>Game Controller</span>-->
<!--                        </span>-->
<!--						<span class="listitem-value">64</span>-->
<!--					</li>-->
<!--					<li>-->
<!--                        <span>-->
<!--                            <img src="assets/demo/images/product/lime-band.jpg" alt="diamond-layout"/>-->
<!--                            <span>Lime Band</span>-->
<!--                        </span>-->
<!--						<span class="listitem-value">62</span>-->
<!--					</li>-->
<!--					<li>-->
<!--                        <span>-->
<!--                            <img src="assets/demo/images/product/gold-phone-case.jpg" alt="diamond-layout"/>-->
<!--                            <span>Phone Case</span>-->
<!--                        </span>-->
<!--						<span class="listitem-value">55</span>-->
<!--					</li>-->
<!--					<li>-->
<!--                        <span>-->
<!--                            <img src="assets/demo/images/product/green-t-shirt.jpg" alt="diamond-layout"/>-->
<!--                            <span>Green T-Shirt</span>-->
<!--                        </span>-->
<!--						<span class="listitem-value">48</span>-->
<!--					</li>-->
<!--					<li>-->
<!--                        <span>-->
<!--                            <img src="assets/demo/images/product/purple-t-shirt.jpg" alt="diamond-layout"/>-->
<!--                            <span>Purple T-Shirt</span>-->
<!--                        </span>-->
<!--						<span class="listitem-value">32</span>-->
<!--					</li>-->
<!--				</ul>-->
<!--			</div>-->
		</div>

        <div class="col-12 lg:col-8">
            <div class="card revenue h-full">
                <h4>Player activity</h4>
<!--                <p>Comparison of your revenue sources.</p>-->
                <div class="revenue-chart-container flex justify-content-center">
                    <p-chart type="pie" id="revenue-chart" [data]="revenueChart" [style]="{'width': '50%'}"></p-chart>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="card">
                <h4>Top Players</h4>
                <ul class="widget-person-list">
                    <li>
                        <div class="person-item">
<!--                            <img src="assets/demo/images/avatar/amyelsner.png" alt="diamond-layout" />-->
                            <div class="avatar">
                                <i class="icon-user"></i>
                            </div>
                            <div class="person-info">
                                <div class="person-name">John Doe</div>
                                <div class="person-subtext dashboard-green">GGR - 123$</div>
                            </div>
                        </div>
                        <div class="person-actions">
                            <button pButton type="button" class="p-button-rounded p-button-success" icon="pi pi-envelope"></button>
                            <button pButton type="button" class="p-button-rounded p-button-warning" icon="pi pi-cog"></button>
                        </div>
                    </li>
                    <li>
                        <div class="person-item">
<!--                            <img src="assets/demo/images/avatar/annafali.png" alt="diamond-layout" />-->
                            <div class="avatar">
                                <i class="icon-user"></i>
                            </div>
                            <div class="person-info">
                                <div class="person-name">John Smith</div>
                                <div class="person-subtext dashboard-green">GGR - 24$</div>
                            </div>
                        </div>
                        <div class="person-actions">
                            <button pButton type="button" class="p-button-rounded p-button-success" icon="pi pi-envelope" ></button>
                            <button pButton type="button" class="p-button-rounded p-button-warning" icon="pi pi-cog" ></button>
                        </div>
                    </li>
                    <li>
                        <div class="person-item">
<!--                            <img src="assets/demo/images/avatar/bernardodominic.png" alt="diamond-layout" />-->
                            <div class="avatar">
                                <i class="icon-user"></i>
                            </div>
                            <div class="person-info">
                                <div class="person-name">Bernardo Dominic</div>
                                <div class="person-subtext dashboard-green">GGR - 73$</div>
                            </div>
                        </div>
                        <div class="person-actions">
                            <button pButton type="button" class="p-button-rounded p-button-success" icon="pi pi-envelope" ></button>
                            <button pButton type="button" class="p-button-rounded p-button-warning" icon="pi pi-cog" ></button>
                        </div>
                    </li>
                    <li>
                        <div class="person-item">
<!--                            <img src="assets/demo/images/avatar/ivanmagalhaes.png" alt="diamond-layout" />-->
                            <div class="avatar">
                                <i class="icon-user"></i>
                            </div>
                            <div class="person-info">
                                <div class="person-name">Ivan Magalhaes</div>
                                <div class="person-subtext dashboard-green">GGR - 19$</div>
                            </div>
                        </div>
                        <div class="person-actions">
                            <button pButton type="button" class="p-button-rounded p-button-success" icon="pi pi-envelope" ></button>
                            <button pButton type="button" class="p-button-rounded p-button-warning" icon="pi pi-cog" ></button>
                        </div>
                    </li>
                    <!--                    <li>-->
                    <!--                        <div class="person-item">-->
                    <!--                            <img src="assets/demo/images/avatar/xuxuefeng.png" alt="diamond-layout" />-->
                    <!--                            <div class="person-info">-->
                    <!--                                <div class="person-name">Xuxue Feng</div>-->
                    <!--                                <div class="person-subtext dashboard-green">Management</div>-->
                    <!--                            </div>-->
                    <!--                        </div>-->
                    <!--                        <div class="person-actions">-->
                    <!--                            <button pButton type="button" class="p-button-rounded p-button-success" icon="pi pi-envelope" ></button>-->
                    <!--                            <button pButton type="button" class="p-button-rounded p-button-warning" icon="pi pi-cog" ></button>-->
                    <!--                        </div>-->
                    <!--                    </li>-->
                </ul>
            </div>
        </div>
	</div>
</div>
