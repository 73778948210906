import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Methods } from '@core/enums';
import { HttpService } from '@core/services';
import { environment } from '@env/environment';
import {
    IEmail,
    ISms,
    IResponseWithCount,
} from '@core/interfaces';

@Injectable({
    providedIn: 'root'
})
export class NotificationsService {

    constructor(private http: HttpService) {
    }

    public sendPlayerEmail(sendEmailObj): Observable<any> {

        const payload = {
            Controller: 'Client',
            Method: 'SendEmailToClient',
            requestObject: sendEmailObj
        };

        return this.http.request(
            'post',
            environment.ApiUrl + Methods.MAIN_REQUEST, payload,
            false,
            null,
            true
        );
    }

    public getEmails(reqObj: any): Observable<IResponseWithCount<IEmail[]>> {
        const payload = {
            Controller: 'Client',
            Method: 'GetEmails',
            RequestObject: reqObj
        };
        return this.http.request(
            'post',
            environment.ApiUrl + Methods.MAIN_REQUEST, payload,
            false,
            null,
            true
        );
    }

    public getSmses(reqObj: any): Observable<IResponseWithCount<ISms>> {
        const payload = {
            Controller: 'Client',
            Method: 'GetSmses',
            RequestObject: reqObj
        };
        return this.http.request(
            'post',
            environment.ApiUrl + Methods.MAIN_REQUEST, payload,
            false,
            null,
            true
        );
    }

}
