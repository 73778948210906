import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

import { CommonService, QuickSearchService, ToastService } from '@core/services';

@Component({
    selector: 'app-quick-search',
    templateUrl: './app.quick-search.component.html',
    styleUrls: ['./app.quick-search.component.scss']
})
export class AppQuickSearchComponent implements OnInit, OnDestroy {
    private unsubscribe$: Subject<void> = new Subject<void>();
    public quickSearchForm: UntypedFormGroup = new UntypedFormGroup({
        Player: new UntypedFormGroup({
            PlayerId: new UntypedFormControl(''),
            Username: new UntypedFormControl(''),
            Name: new UntypedFormControl(''),
            Email: new UntypedFormControl(''),
            MobileNumber: new UntypedFormControl(''),
        }),
        Document: new UntypedFormGroup({
            DepositId: new UntypedFormControl(''),
            WithdrawalId: new UntypedFormControl(''),
            InternetBetId: new UntypedFormControl(''),
            ExternalId: new UntypedFormControl('')
        })
    });

    constructor(
        public quickSearchService: QuickSearchService,
        private commonService: CommonService,
        private toastService: ToastService,
        private router: Router,
    ) {
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public onOpenQuickSearch(): void {
        this.quickSearchService.quickSearchActive = !this.quickSearchService.quickSearchActive;
    }

    public onQuickSearch(groupName: string, controlName: string, rootUrl: string): void {
        if (!this.quickSearchForm.get([groupName, controlName]).value) {
            return;
        }

        switch (controlName) {
            case 'PlayerId':
            case 'DepositId':
            case 'WithdrawalId':
                const windowReference = window.open();
                windowReference.location = `${rootUrl}/${this.quickSearchForm.get([groupName, controlName]).value}`;
                break;
            default:
                this.navigateToResultListPage(groupName, controlName, rootUrl);
        }
    }

    private navigateToResultListPage(groupName: string, controlName: string, rootUrl: string): void {
        const queryParams = {};
        queryParams[controlName.toLowerCase()] = this.quickSearchForm.get([groupName, controlName]).value;

        const url = this.router.serializeUrl(
            this.router.createUrlTree([`/${rootUrl}`], { queryParams })
        );

        const windowReference = window.open();
        windowReference.location = url;
    }
}
